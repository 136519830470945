import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import brLocale from "date-fns/locale/pt-BR";

const DateInput = ({
  variant = "inline",
  format = "dd/MM/yyyy",
  value,
  onChange,
  maxDate,
  getHours,
  error,
  sx,
}) => {
  const styles = (sx) => ({
    textField: {
      sx: {
        width: sx?.width || "100%",
        backgroundColor: sx?.backgroundColor || "#FFFFFF", // Use theme for background color
        borderRadius: sx?.borderRadius || "12px",
        border: error ? "1px solid #d32f2f" : sx?.border || "1px solid #7D7D7D",
        height: sx?.height || "38px",
        "&:hover": {
          borderColor: "#000",
        },
        "& .MuiFormLabel-root, & .MuiInputBase-root, & .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root":
          {
            color: sx?.color || "#000", // Use theme for text color
            padding: sx?.padding || 0,
            fontFamily: "Montserrat",
            fontSize: sx?.fontSize || 14,
            borderRadius: sx?.borderRadius || "12px",
            border: "none",
            height: sx?.height || "38px",
            minWidth: sx?.minWidth || "160px !important",
            "& input:disabled": {
              color: "#757575 !important", // Use theme for disabled color
            },
          },
        "& .MuiIconButton-root": {
          color: "#222222",
          marginRight: "10px",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:disabled": {
            color: "#757575 !important", // Use theme for disabled color
          },
        },
      },
    },
    //popover calendar (underneath calendar)
    popper: {
      sx: {
        borderRadius: "12px",
        backgroundColor: "transparent",
        overflow: "hidden",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
        border: "1px solid rgba(100, 100, 111, 0.2)",
      },
    },
    //calendar
    layout: {
      sx: {
        color: "#1565c0",
        borderRadius: "12px",
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
      {getHours ? (
        <DateTimePicker
          variant={variant}
          value={value}
          onChange={onChange}
          disableToolbar={true}
          placeholder="DD/MM/AAAA HH:MM"
          disableFuture
          timezone="America/Sao_Paulo"
          minDate={new Date("1998-01-01")}
          slotProps={styles(sx)}
        />
      ) : (
        <DatePicker
          variant={variant}
          format={format}
          views={null}
          value={value}
          onChange={onChange}
          disableToolbar={true}
          placeholder="DD/MM/AAAA"
          maxDate={maxDate || null}
          minDate={new Date("2014-01-01")}
          slotProps={styles(sx)}
        />
      )}
    </LocalizationProvider>
  );
};

export default DateInput;
