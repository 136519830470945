import { formatDateToTable } from "~/presentation/functions/date";

export const statsTableColumns = {
  search: [
    {
      field: "date",
      headerName: "Data",
      filterable: false,
      renderCell: ({ value }) => (value ? formatDateToTable(value) : "-"),
      flex: 1,
      align: "center",
    },
    { field: "userName", flex: 1, align: "center", headerName: "Usuário" },
    { field: "companyType", flex: 1, align: "center", headerName: "Tipo de Empresa" },
    { field: "cnae", flex: 1, align: "center", headerName: "CNAE" },
    { field: "term", flex: 1, align: "center", headerName: "Termo" },
    { field: "categoryName", flex: 1, align: "center", headerName: "Categoria" },
    { field: "branch", flex: 1, align: "center", headerName: "Filial" },
    { field: "simples", flex: 1, align: "center", headerName: "Simples" },
    { field: "cityHire", flex: 1, align: "center", headerName: "Cidade Contratante" },
    { field: "cityService", flex: 1, align: "center", headerName: "Cidade Prestadora" },
    { field: "cityContractor", flex: 1, align: "center", headerName: "Cidade Contratada" },
  ],
  "search-summary": [
    { field: "userName", flex: 1, align: "center", headerName: "Usuário" },
    {
      field: "fisrtSearch",
      flex: 1,
      align: "center",
      headerName: "Primeira busca",
      filterable: false,
      renderCell: ({ value }) => (value ? formatDateToTable(value) : "-"),
    },
    {
      field: "lastSearch",
      flex: 1,
      align: "center",
      headerName: "Última busca",
      filterable: false,
      renderCell: ({ value }) => (value ? formatDateToTable(value) : "-"),
    },
    { field: "companyName", flex: 1, align: "center", headerName: "Empresa" },
    { field: "amount", flex: 1, align: "center", headerName: "Quantidade" },
    { field: "percent", flex: 1, align: "center", headerName: "Porcentagem" },
  ],
  "content-access": [
    { field: "title", flex: 1, align: "center", headerName: "Título" },
    { field: "amount", flex: 1, align: "center", headerName: "Quantidade" },
  ],
};
