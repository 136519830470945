import React, { useEffect, useRef, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Box, Checkbox, FormControlLabel, Input, Pagination, Typography } from "@mui/material";
import ButtonCommon from "../Common/Button";
import { Search } from "@mui/icons-material";
import PageListHeader from "~/presentation/views/Admin/components/PageListHeader";
import { formStyles } from "../Styles/formStyles";

const ListComponent = ({
  data,
  title,
  handleAdd,
  handleCreate,
  titleAdd,
  checkedData = [],
  onClose,
  disablePadding = true,
  itemsPerPage = 10,
  securePaths = [],
  blockCheckNumber = null,
  costumFilter = null, //"QSO", "AddInBulk"
}) => {
  const searchRef = useRef();

  const [checked, setChecked] = useState([]);
  const idsChecked = checked.length > 0 ? checked.map((i) => i.id) : [];
  const verifyChecked = (id) => idsChecked.includes(id);

  const [filteredData, setFilteredData] = useState([]);

  const [page, setPage] = useState(1);
  const handleChangePage = (_, newPage) => setPage(newPage);
  const paginatedData = filteredData?.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const showSearchAndPagination = data?.length > itemsPerPage;
  const blockCheckBoxes = blockCheckNumber && checked.length >= blockCheckNumber;

  const handleToggle = (value, isChecked) => () => {
    if (isChecked) {
      setChecked((prev) => prev.filter((selectedItem) => selectedItem.id !== value.id));
    } else {
      let uptadedArray = [...checked];
      if (blockCheckBoxes) return;
      uptadedArray.push(value);
      setChecked(uptadedArray);
    }
  };

  const handleSearch = (searchText) => {
    setPage(1);
    const lowercasedSearchText = searchText.toLowerCase();
    setFilteredData(() => {
      return data.filter((value) => {
        const searchableString = renderText(value).toLowerCase();
        return searchableString.includes(lowercasedSearchText);
      });
    });
  };

  useEffect(() => {
    let firstRender = false;
    if (checkedData?.length > 0) {
      if (!firstRender) {
        setChecked([...checkedData]);
        firstRender = true;
      }
    }
  }, [checkedData]);

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const renderText = (value) => {
    if (value?.code && value?.description && value?.detailCode) {
      return `${value.code} (${value.detailCode}) - ${value.description};`;
    }

    if (value?.code && value?.description) {
      return value.code + " - " + value.description;
    }

    if (value?.name) {
      return value.name;
    }

    if (value?.label) {
      return value.label;
    }

    if (value?.term) {
      return value.term;
    }

    if (value?.title && value?.description) {
      return value.title + " - " + value.description;
    }

    if (value?.title) {
      return value.title;
    }

    if (value?.description) {
      return value.description;
    }

    if (value?.value) {
      return value.value;
    }
  };

  const QSO = ["A", "B", "C", "D"];

  const handleCheckAllQSOItems = (letter, data, isChecked) => {
    if (isChecked) {
      setChecked((prev) => prev.filter((selectedItem) => selectedItem.letter !== letter));
    } else {
      setChecked((prev) => [...prev, ...data]);
    }
  };

  const CostumFilter = () => {
    switch (costumFilter) {
      case "QSO":
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={formStyles.subtitle}>Adicione por QSO:</Typography>
            <Box>
              {QSO.map((letter) => {
                const allItemsQSO = data.filter((i) => i.letter === letter);
                const allItemsAreChecked =
                  allItemsQSO.length > 0 && allItemsQSO.every((item) => verifyChecked(item.id));
                return (
                  <FormControlLabel
                    key={letter}
                    sx={formStyles.controlLabel}
                    control={
                      <Checkbox
                        sx={formStyles.icon}
                        edge="end"
                        onChange={() =>
                          handleCheckAllQSOItems(letter, allItemsQSO, allItemsAreChecked)
                        }
                        checked={allItemsAreChecked}
                      />
                    }
                    label={letter}
                  />
                );
              })}
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <PageListHeader
        title={title}
        handleAdd={handleCreate}
        fontSize={20}
        titleAdd={titleAdd}
        handleBack={onClose}
        addSecurePath={securePaths?.length > 0 ? securePaths : null}
      />
      <Box sx={{ padding: 3 }}>
        <Box sx={styles.filters}>
          {costumFilter && <CostumFilter />}
          {showSearchAndPagination && (
            <Input
              type="text"
              placeholder="Pesquisar..."
              ref={searchRef}
              onChange={(event) => handleSearch(event.target.value)}
              disableUnderline
              endAdornment={<Search />}
              sx={styles.input}
            />
          )}
        </Box>
        <Box>
          <Typography onClick={() => setChecked([])} sx={styles.cleanAllButton}>
            Limpar selecionados
          </Typography>
          <List sx={styles.list}>
            {paginatedData.map((value, index) => {
              const isChecked = verifyChecked(value?.id);
              return (
                <ListItem
                  key={`${value.id}-${value.name}-${index}`}
                  secondaryAction={
                    <Checkbox
                      sx={formStyles.icon}
                      edge="end"
                      onChange={handleToggle(value, isChecked)}
                      checked={isChecked}
                      disabled={blockCheckBoxes && !isChecked}
                    />
                  }
                  disablePadding={disablePadding}
                >
                  <ListItemText sx={{ maxWidth: "90%" }} primary={renderText(value)} />
                </ListItem>
              );
            })}
          </List>
          {filteredData?.length > itemsPerPage && (
            <Pagination
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              count={Math.ceil(filteredData.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </Box>
        {handleAdd && (
          <Box sx={styles.addButton}>
            <ButtonCommon
              onClick={() => {
                handleAdd(checked);
                onClose();
              }}
            >
              Salvar
            </ButtonCommon>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ListComponent;

const styles = {
  list: {
    width: "100%",
  },
  cleanAllButton: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    fontSize: 14,
    fontFamily: "Montserrat",
    cursor: "pointer",
    "&:hover": { textDecoration: "underline" },
    paddingY: 1,
  },
  filters: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  input: {
    width: "min(100%, 350px)",
    marginInLine: "auto",
    height: "36px",
    mb: 1,
    background: "#E6E6E6",
    borderRadius: "12px",
    border: "1px solid #000000",
    padding: "0px 10px",
    "& input::placeholder": {
      color: "gray",
      opacity: 1,
    },
    "&:hover": {
      border: "2px solid #000000",
    },
    "&:focus": {
      border: "2px solid #000000",
    },
    "@media (min-width: 875px)": {
      width: "300px",
    },
    "@media (min-width: 1200px)": {
      width: "375px",
    },
  },
  addIcon: {
    backgroundColor: "#001A50",
    padding: "2px",
    borderRadius: "12px",
  },
  titleAndButtonContainer: {
    background: "#E6E6E6",
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  addButton: {
    marginTop: 2,
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
  },
};
