import React, { useEffect, useRef } from "react";

function Shake({ children, duration = "0.4s", trigger = false, ...props }) {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;

    if (trigger) {
      element.classList.add("shake");

      setTimeout(
        () => {
          element.classList.remove("shake");
        },
        parseFloat(duration) * 1000
      );
    }
  }, [trigger, duration]);

  return (
    <>
      <div ref={elementRef} className="shake-container" {...props}>
        {children}
      </div>

      <style>
        {`
          @keyframes shake {
            0%, 100% { transform: translateX(0); }
            20% { transform: translateX(-5px); }
            40% { transform: translateX(5px); }
            60% { transform: translateX(-5px); }
            80% { transform: translateX(5px); }
          }

          .shake {
            animation: shake ${duration} ease-in-out;
          }
        `}
      </style>
    </>
  );
}

export default Shake;
