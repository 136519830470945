export const adminPerms = {
  // Empresas
  seeCompanies: "admin\\cadastro\\empresa\\visualizar",
  addCompanies: "admin\\cadastro\\empresa\\adicionar",
  editCompanies: "admin\\cadastro\\empresa\\editar",
  deleteCompanies: "admin\\cadastro\\empresa\\excluir",

  // ECategorias Empresa
  seeCompanyCategory: "admin\\cadastro\\categoria-empresa\\visualizar",
  addCompanyCategory: "admin\\cadastro\\categoria-empresa\\adicionar",
  editCompanyCategory: "admin\\cadastro\\categoria-empresa\\editar",
  deleteCompanyCategory: "admin\\cadastro\\categoria-empresa\\excluir",

  // Inscrição
  seeRegistrations: "admin\\cadastro\\inscricao\\visualizar",
  addRegistrations: "admin\\cadastro\\inscricao\\adicionar",
  editRegistrations: "admin\\cadastro\\inscricao\\editar",
  deleteRegistrations: "admin\\cadastro\\inscricao\\excluir",

  // Planos
  seePlans: "admin\\cadastro\\plano\\visualizar",
  addPlans: "admin\\cadastro\\plano\\adicionar",
  editPlans: "admin\\cadastro\\plano\\editar",
  deletePlans: "admin\\cadastro\\plano\\excluir",

  // Usuários
  seeUsers: "admin\\cadastro\\usuario\\visualizar",
  addUsers: "admin\\cadastro\\usuario\\adicionar",
  editUsers: "admin\\cadastro\\usuario\\editar",
  deleteUsers: "admin\\cadastro\\usuario\\excluir",

  // Gerar senha usuário
  generateUsersPassword: "admin\\cadastro\\usuario\\gerar-senha",

  // Cidades
  seeCities: "admin\\cadastro\\municipio\\visualizar",
  addCities: "admin\\cadastro\\municipio\\adicionar",
  editCities: "admin\\cadastro\\municipio\\editar",
  deleteCities: "admin\\cadastro\\municipio\\excluir",

  // Variáveis de valor
  seeValueVariables: "admin\\cadastro\\variavel\\visualizar",
  addValueVariables: "admin\\cadastro\\variavel\\adicionar",
  editValueVariables: "admin\\cadastro\\variavel\\editar",
  deleteValueVariables: "admin\\cadastro\\variavel\\excluir",

  // Enquetes
  seeSurvey: "admin\\cadastro\\enquete\\visualizar",
  addSurvey: "admin\\cadastro\\enquete\\adicionar",
  editSurvey: "admin\\cadastro\\enquete\\editar",
  deleteSurvey: "admin\\cadastro\\enquete\\excluir",

  // Categorias-site
  seeBlogCategory: "admin\\site\\categoria\\visualizar",
  addBlogCategory: "admin\\site\\categoria\\adicionar",
  editBlogCategory: "admin\\site\\categoria\\editar",
  deleteBlogCategory: "admin\\site\\categoria\\excluir",

  // Blog
  seeBlog: "admin\\site\\blog\\visualizar",
  addBlog: "admin\\site\\blog\\adicionar",
  editBlog: "admin\\site\\blog\\editar",
  deleteBlog: "admin\\site\\blog\\excluir",

  // Comentários
  seeComments: "admin\\site\\comentario\\visualizar",
  addComments: "admin\\site\\comentario\\adicionar",
  editComments: "admin\\site\\comentario\\editar",
  deleteComments: "admin\\site\\comentario\\excluir",

  // Conteúdo Selecionado
  seeContent: "admin\\site\\conteudo\\visualizar",
  addContent: "admin\\site\\conteudo\\adicionar",
  editContent: "admin\\site\\conteudo\\editar",
  deleteContent: "admin\\site\\conteudo\\excluir",

  // Video
  seeVideo: "admin\\site\\video\\visualizar",
  addVideo: "admin\\site\\video\\adicionar",
  editVideo: "admin\\site\\video\\editar",
  deleteVideo: "admin\\site\\video\\excluir",

  // Video Category
  seeVideoCategory: "admin\\site\\categoria-video\\visualizar",
  addVideoCategory: "admin\\site\\categoria-video\\adicionar",
  editVideoCategory: "admin\\site\\categoria-video\\editar",
  deleteVideoCategory: "admin\\site\\categoria-video\\excluir",

  // CNAE
  seeCnaes: "admin\\gtfacil\\cnae\\visualizar",
  addCnaes: "admin\\gtfacil\\cnae\\adicionar",
  editCnaes: "admin\\gtfacil\\cnae\\editar",
  deleteCnaes: "admin\\gtfacil\\cnae\\excluir",

  // Códigos de serviço
  seeServiceCodes: "admin\\gtfacil\\codigo-servico\\visualizar",
  addServiceCodes: "admin\\gtfacil\\codigo-servico\\adicionar",
  editServiceCodes: "admin\\gtfacil\\codigo-servico\\editar",
  deleteServiceCodes: "admin\\gtfacil\\codigo-servico\\excluir",

  // Anotações
  seeAnnotations: "admin\\gtfacil\\anotacao\\visualizar",
  addAnnotations: "admin\\gtfacil\\anotacao\\adicionar",
  editAnnotations: "admin\\gtfacil\\anotacao\\editar",
  deleteAnnotations: "admin\\gtfacil\\anotacao\\excluir",

  // Termos de pesquisa
  seeSearchTerms: "admin\\gtfacil\\termo-pesquisa\\visualizar",
  addSearchTerms: "admin\\gtfacil\\termo-pesquisa\\adicionar",
  editSearchTerms: "admin\\gtfacil\\termo-pesquisa\\editar",
  deleteSearchTerms: "admin\\gtfacil\\termo-pesquisa\\excluir",

  // Items de Resposta
  seeAnswerItems: "admin\\gtfacil\\item-resposta\\visualizar",
  addAnswerItems: "admin\\gtfacil\\item-resposta\\adicionar",
  editAnswerItems: "admin\\gtfacil\\item-resposta\\editar",
  deleteAnswerItems: "admin\\gtfacil\\item-resposta\\excluir",

  // Tipos de Resposta
  seeAnswerTypes: "admin\\gtfacil\\tipo-resposta\\visualizar",
  addAnswerTypes: "admin\\gtfacil\\tipo-resposta\\adicionar",
  editAnswerTypes: "admin\\gtfacil\\tipo-resposta\\editar",
  deleteAnswerTypes: "admin\\gtfacil\\tipo-resposta\\excluir",

  // Respostas
  seeAnswers: "admin\\gtfacil\\resposta\\visualizar",
  addAnswers: "admin\\gtfacil\\resposta\\adicionar",
  editAnswers: "admin\\gtfacil\\resposta\\editar",
  deleteAnswers: "admin\\gtfacil\\resposta\\excluir",

  // Respostas ISS
  seeIssAnswers: "admin\\gtfacil\\resposta-iss\\visualizar",
  addIssAnswers: "admin\\gtfacil\\resposta-iss\\adicionar",
  editIssAnswers: "admin\\gtfacil\\resposta-iss\\editar",
  deleteIssAnswers: "admin\\gtfacil\\resposta-iss\\excluir",

  // Importar respostas
  seeAnswerImport: "admin\\gtfacil\\importar-resposta\\visualizar",

  // Informação Fiscal
  seeFiscalInformation: "admin\\gtfacil\\informacao-fiscal\\visualizar",

  // Perfis
  seeUserProfile: "admin\\cadastro\\perfil-usuario\\visualizar",
  addUserProfile: "admin\\cadastro\\perfil-usuario\\adicionar",
  editUserProfile: "admin\\cadastro\\perfil-usuario\\editar",
  deleteUserProfile: "admin\\cadastro\\perfil-usuario\\excluir",
};

// CLIENTE
export const clientPerms = {
  // Perfis
  seeUserProfile: "client\\cadastro\\perfil-usuario\\visualizar",
  addUserProfile: "client\\cadastro\\perfil-usuario\\adicionar",
  deleteUserProfile: "client\\cadastro\\perfil-usuario\\excluir",
  editUserProfile: "client\\cadastro\\perfil-usuario\\editar",

  // Usuários
  seeUsers: "client\\painel\\usuario\\visualizar",
  addUsers: "client\\painel\\usuario\\adicionar",
  editUsers: "client\\painel\\usuario\\editar",
  deleteUsers: "client\\painel\\usuario\\excluir",

  // Informação Fiscal
  seeFiscalInformation: "client\\painel\\informacao-fiscal\\visualizar",
  addFiscalInformation: "client\\painel\\informacao-fiscal\\adicionar",

  // Pesquisa CNAE
  seeCnaeResearch: "client\\gtfacil\\pesquisa-cnae\\visualizar",

  // Pesquisa LC
  seeLcResearch: "client\\gtfacil\\pesquisa-lc\\visualizar",

  // Pesquisa Livre
  seeFreeSearch: "client\\gtfacil\\pesquisa-livre\\visualizar",

  // Legislação
  seeLegislation: "client\\conteudo-selecionado\\legislacao\\visualizar",

  // Artigos
  seeArticles: "client\\conteudo-selecionado\\artigos\\visualizar",

  // Multimídea
  seeMultimedia: "client\\conteudo-selecionado\\multimidia\\visualizar",

  // Blog
  seeBlog: "client\\conteudo-selecionado\\blog\\visualizar",
};

// REINF
export const reinfPerms = {
  // Empresa
  seeCompany: "reinf\\empresa\\visualizar",
  editCompany: "reinf\\empresa\\editar",

  //Estabelecimentos
  addEstablishment: "reinf\\estabelecimento\\adicionar",
  editEstablishment: "reinf\\estabelecimento\\editar",
  deleteEstablishment: "reinf\\estabelecimento\\excluir",

  //Envios
  sendR1000: "reinf\\envio\\r1000",
  sendR2010: "reinf\\envio\\r2010",
  sendR2020: "reinf\\envio\\r2020",
  sendR2098: "reinf\\envio\\r2098",
  sendR2099: "reinf\\envio\\r2099",
  sendR4010: "reinf\\envio\\r4010",
  sendR4020: "reinf\\envio\\r4020",
  sendR4099: "reinf\\envio\\r4099",
  sendR9000: "reinf\\envio\\r9000",

  //Retificar eventos
  sendRectification: "reinf\\envio\\retificacao",

  //Certificados digitais
  seeCertificate: "reinf\\certificado-digital\\visualizar",
  addCertificate: "reinf\\certificado-digital\\adicionar",
  editCertificate: "reinf\\certificado-digital\\editar",
  deleteCertificate: "reinf\\certificado-digital\\excluir",

  //Importar Docs
  importSiafi: "reinf\\importacao\\siafi",
  importXml: "reinf\\importacao\\xml",
  importSpreadsheet: "reinf\\importacao\\planilha",

  //Consultar situação protocolos
  checkProtocol: "reinf\\consulta\\protocolo",
  //Envio protocolo
  sendProtocol: "reinf\\envio\\protocolo",

  //Documentos Fiscais
  seeFiscalDocument: "reinf\\documento-fiscal\\visualizar",
  addFiscalDocument: "reinf\\documento-fiscal\\adicionar",
  deleteFiscalDocument: "reinf\\documento-fiscal\\excluir",
  editFiscalDocument: "reinf\\documento-fiscal\\editar",
};

export const securityErrorMessage = (message) => "Não possui permissão para " + message;
