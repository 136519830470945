import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import validation from "./validation";
import { Avatar, Box, Button, Grid2 as Grid, Typography } from "@mui/material";
import Tree from "~/presentation/components/TreeView/Tree";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";

function ProfileForms({ data, userProfilesUseCases, companyId, refreshData, onCloseForm }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem("GT_user")) || [];
  const isCreate = data.id === 0;

  const fetchPermissions = async (id) => {
    openBackdrop(true, "Carregando permissões");
    setIsLoading(true);
    try {
      const data = await userProfilesUseCases.checkProfilePermissions(id);
      let permisReinf = [];
      //aqui em client não é para renderizar as permissões de admin
      if (user?.companyInfor?.companyPlan?.isAllowReinf) {
        permisReinf =  data.reinfs || [] ;
      }
      setPermissions({
        clients: data.clients || [],
        reinfs: permisReinf,
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar permissões. " + error,
        type: "error",
      });
    } finally {
      openBackdrop(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setValues(data);
      fetchPermissions(data.id || 0);
    }
  }, [data]);

  const handleChangeValue = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const extractAllowedPermissions = (permissions) => {
    let allowedPermissions = [];
    Object.keys(permissions).forEach((categoryKey) => {
      permissions[categoryKey].forEach((category) => {
        category.items.forEach((item) => {
          if (item.isAllow) {
            if (!allowedPermissions.includes(item.id)) {
              allowedPermissions.push(item.id);
            }
          }
        });
      });
    });
    return allowedPermissions;
  };

  const handleValidation = async () => {
    const submitValues = {
      id: values.id,
      name: values.name,
      isInternalUse: values.isInternalUse,
      companyId: companyId,
      permissionIDs: extractAllowedPermissions(permissions),
    };

    validation
      .validate(submitValues, { abortEarly: false })
      .then(async () => handleSubmit(submitValues))
      .catch((errors) => {
        openBackdrop(false);
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  const handleSubmit = async (submitValues) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} perfil`);

    try {
      if (isCreate) {
        await userProfilesUseCases.createProfile(submitValues);
      } else {
        await userProfilesUseCases.updateProfile(submitValues);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Perfil ${isCreate ? "criado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.data || error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handlePermissionChange = (category, itemId) => {
    setPermissions((prev) => ({
      ...prev,
      [category]: prev[category].map((item) => ({
        ...item,
        items: item.items.map((subItem) =>
          subItem.id === itemId ? { ...subItem, isAllow: !subItem.isAllow } : subItem
        ),
      })),
    }));
    clearError("permissionIDs");
  };

  const keysArray = values?.permission && Object.keys(values?.permission);
  const keysArrayWithoutAdmin = keysArray?.filter((item) => item !== "admins");

  const renderUsers = () => {
    if (values?.users?.length > 0) {
      return values.users.map((u, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar sx={{ textTransform: "capitalize", fontWeight: 700 }}>
            {u.name.split("")[0]}
          </Avatar>
          <Typography sx={formStyles.subtitle}>{u.name || u.email}</Typography>
        </Box>
      ));
    }
  };

  return (
    <>
      <Box sx={{ padding: { xs: 1, md: 3 } }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }} sx={styles.halfContainer}>
            <Typography sx={{ ...formStyles.title, color: errors.name && "#d32f2f" }}>
              Descrição
            </Typography>
            <Input
              title="Nome"
              type="text"
              value={values.name}
              styles={styles.field}
              name="name"
              onChange={(e) => {
                handleChangeValue("name", e.target.value);
              }}
              error={errors.name}
            />
            <Box sx={{ marginTop: 1 }}>
              <Typography sx={formStyles.subtitle}>Usuários do Perfil</Typography>
              <Box
                sx={{
                  background: "#EFF2F4",
                  borderRadius: "3px",
                  border: "1px solid #777777",
                  width: "100%",
                  padding: "8px 12px",
                  height: "418px",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                  gap: 1,
                }}
              >
                {renderUsers()}
              </Box>
            </Box>
          </Grid>

          <Grid
            size={{ xs: 12, sm: 6 }}
            sx={{
              ...styles.halfContainer,
              borderColor: errors.permissionIDs && "#d32f2f",
            }}
          >
            <Typography sx={{ ...formStyles.title, color: errors.permissionIDs && "#d32f2f" }}>
              Permissões
            </Typography>
            <Tree
              data={permissions}
              onPermissionChange={handlePermissionChange}
              isLoading={isLoading}
              arrayLoading={keysArrayWithoutAdmin}
              iconColor="#081445"
              inputSearchStyle={{
                marginLeft: "none",
                backgroundColor: "#EFF2F4",
                borderRadius: "3px",
                border: "1px solid #777",
              }}
              treeStyles={{
                background: "#EFF2F4",
                borderRadius: "3px",
                border: "1px solid #777777",
              }}
              error={errors.permissionIDs}
            />
            {errors.permissionIDs && (
              <Typography sx={{ ...formStyles.errorMsg, marginTop: "auto" }}>
                {errors.permissionIDs}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Box sx={styles.ButtonGroup}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button sx={styles.CancelButton} variant="contained" onClick={onCloseForm}>
              Cancelar
            </Button>
            <Button sx={styles.Button} variant="contained" onClick={handleValidation}>
              Salvar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ProfileForms;

const styles = {
  halfContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: { md: "550px" },
    maxHeight: "550px",
    borderRadius: "12px",
    borderWidth: "1px",
    borderColor: "#eee",
  },
  ButtonGroup: { display: "flex", gap: 1, marginTop: 2, justifyContent: "end" },
  Button: {
    background: "#081445",
    paddingInline: { xs: 2, md: 4 },
    paddingBlock: 1,
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0e3279",
    },
  },
  DeleteButton: {
    border: "1px solid red",
    color: "red",
    "&:hover": {
      border: "1px solid red",
    },
  },
  CancelButton: {
    backgroundColor: "#EFF2F4",
    color: "black",
    "&:hover": {
      color: "#fff",
    },
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    textAlign: { xs: "center", md: "left" },
  },
  field: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    borderRadius: "3px",
    borderWidth: "1px",
  },
  searchField: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    borderRadius: "3px",
    height: "54px",
    border: "1px solid #ccc",
  },
};
