import React, { useContext } from "react";
import { Box, Typography as Text } from "@mui/material";
import { SendR2010 } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendR2010";
import { SendR4020 } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendR4020";
import { SendR2098 } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendR2098";
import { SendR4099 } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendR4099";
import { SendR9000 } from "./SendR9000";
import { SendAutomated } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendAutomated";
import { formatDateToISO } from "../../../Utils/utilsDate";
import { SendR4010 } from "./SendR4010";
import { SendAutomated4010 } from "./SendAutomated4010";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { EventDetailsModal } from "./EventDetailsModal";

export const EventSend = ({
  data = [],
  closeModal = () => {},
  certificateData = [], // certificados array
  refreshData = () => {},
  receiptNumber = "",
  operation = "",
  handleVerifyEventStatus = () => {},
  filter = {},
}) => {
  const { companyId, companyInfor } = JSON.parse(localStorage.getItem("GT_user"));
  const { openSecondDialog, closeSecondDialog } = useContext(DialogContext);
  const eventSend = filter?.event;
  const DigitalCertificate = filter?.certificate;
  const selectedMonth = filter?.month;
  const selectedYear = filter?.year;

  // Filtro com base no id do certificado selecionado
  const certificate = certificateData.find((cert) => cert.id === DigitalCertificate);

  const competence = formatDateToISO(selectedYear, selectedMonth);

  const openDetailsModal = (responseDetails) => {
    openSecondDialog({
      fullWidth: true,
      maxWidth: "sm",
      customComponent: () => (
        <EventDetailsModal
          onClose={() => {
            closeSecondDialog();
            closeModal();
            if (["r2098", "r4099"].includes(eventSend)) return;
            refreshData();
          }}
          eventDetails={responseDetails}
        />
      ),
    });
  };

  const geralProps = {
    nfseDate: data,
    DigitalCertificate: certificate,
    UserCompany: companyInfor,
    CompanyUserId: companyId,
    eventSend: eventSend,
    competence: competence,
    selectedYear: selectedYear,
    selectedMonth: selectedMonth,
    closeModal: closeModal,
    refreshData: refreshData,
    openDetailsModal: openDetailsModal,
  };

  const renderEventSpecificContent = () => {
    switch (eventSend) {
      case "r2010":
        return <SendR2010 {...geralProps} receiptNumber={receiptNumber} operation={operation} />;
      case "r4020":
        return <SendR4020 {...geralProps} receiptNumber={receiptNumber} operation={operation} />;
      case "r4010":
        return <SendR4010 {...geralProps} receiptNumber={receiptNumber} operation={operation} />;
      case "r2098":
        return <SendR2098 {...geralProps} handleVerifyEventStatus={handleVerifyEventStatus} />;
      case "r4099":
        return <SendR4099 {...geralProps} handleVerifyEventStatus={handleVerifyEventStatus} />;
      case "r9000":
        return <SendR9000 {...geralProps} />;

      case "automated-r2010":
      case "automated-r4020":
        return (
          <SendAutomated
            {...geralProps}
            eventSend={eventSend?.split("-r")[1]}
            receiptNumber={receiptNumber}
            operation={operation}
            filter={filter}
          />
        );
      case "automated-r4010":
        return (
          <SendAutomated4010
            {...geralProps}
            eventSend={eventSend?.split("-r")[1]}
            receiptNumber={receiptNumber}
            operation={operation}
            filter={filter}
          />
        );
      default:
        return <Text>Nenhum evento selecionado para envio</Text>;
    }
  };

  return <Box sx={styles.Modal}>{renderEventSpecificContent()}</Box>;
};

const styles = {
  Modal: {
    p: 4,
    backgroundColor: "#e6e6e6",
  },
};
