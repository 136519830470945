import React, { useContext } from "react";
import EmbedVideo from "~/presentation/components/EmbedVideo";
import AppContext from "~/presentation/AppContext";
import { clipText } from "~/presentation/utils/clipText";
import { useNavigate } from "react-router-dom";
import { Box, Typography as Text, Button, Paper } from "@mui/material";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { clientPerms } from "~/presentation/security/SecurePaths";

const VideoItem = ({ url, title, text = "", index, publishDate, autor }) => {
  const navigate = useNavigate();
  const { isLogged } = useContext(AppContext);

  const handleNavigateToPost = (id) => {
    if (isLogged()) return navigate("/cliente/blog/post/" + id);
  };

  const hasBlogPermission = hasPermission([clientPerms.seeBlog]) || false;

  return (
    <Paper
      elevation={2}
      sx={{
        background: "#E1E1E1",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: 1,
        borderRadius: "10px",
      }}
    >
      <EmbedVideo url={url} title={title} />

      <Box
        sx={{
          background: "#E1E1E1",
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
          padding: 2,
          borderRadius: "10px",
          minHeight: 350,
        }}
      >
        <Text sx={{ fontSize: 18, fontFamily: "Montserrat", color: "#3A3A3A", fontWeight: 600 }}>
          {title}
        </Text>
        <Text
          sx={{ fontSize: 15 }}
          dangerouslySetInnerHTML={{
            __html: text ? `${clipText({ text, numberOfCharacters: 300 })}` : "",
          }}
        />

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4, mb: 1 }}>
          <Box>
            <Text
              sx={{
                fontFamily: "Montserrat",
                color: "#3A3A3A",
                fontSize: 14,
                fontStyle: "italic",
              }}
            >
              Por: {autor}
            </Text>
            <Text
              sx={{
                fontFamily: "Montserrat",
                color: "#3A3A3A",
                fontSize: 14,
                fontStyle: "italic",
              }}
            >
              {" "}
              Publicado em: {new Date(publishDate).toLocaleDateString()}
            </Text>
          </Box>
          {hasBlogPermission && (
            <Button
              onClick={() => handleNavigateToPost(index, () => {})}
              sx={{
                background: "#FF6829",
                color: "#FFF",
                width: "40%",
                transition: "transform 0.3s ease-out",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  transform: "scale(1.01)",
                  backgroundColor: "#FF6830",
                },
              }}
            >
              Acessar
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default VideoItem;
