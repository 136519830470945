import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWindowSize from "~/presentation/hooks/useWindowSize";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import FilterList from "~/presentation/components/SelectableList";
import useDebounce from "~/presentation/hooks/useDebounce";

const filterMap = {
  inbox: 0,
  answered: 1,
  drafts: 1,
  draft: 2,
  finished: 3,
  all: -1,
};

const MessagesController = ({ getMessages, filtersList, replyMessagePath, commentUseCases }) => {
  const defaultPage = 1;
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { width: windowWidth } = useWindowSize();
  const navigate = useNavigate();

  const [messages, setMessages] = useState();
  const [allMessages, setAllMessages] = useState();
  // filters for data fetching
  const [filter, setFilter] = useState(filtersList[0]);
  const [statusFilter, setStatusFilter] = useState(0);
  // Paginação
  const [page] = useState(defaultPage);
  const [numberOfPages, setNumberOfPages] = useState();

  const [selectedMessages, setSelectedMessages] = useState([]);
  //
  const [mobile, setMobile] = useState();
  const [searchValue, setSearchValue] = React.useState("");

  // debounce hook used for searchValueing. Passing searchValue value and calling searchValue function
  const debouncedSearchValue = useDebounce(searchValue, 400);
  useEffect(() => {
    fetchMessagesWithFilter({ searchValue: debouncedSearchValue });
  }, [debouncedSearchValue]);

  const filterMessages = (messages, searchValueFilter, statusValueFilter) => {
    return messages?.filter(
      (message) =>
        (statusValueFilter
          ? statusValueFilter === -1
            ? true
            : message.status === statusValueFilter
          : message.status === 0) &&
        (message.subject?.toLowerCase().includes(searchValueFilter?.toLowerCase()) ||
          message.commentText?.toLowerCase().includes(searchValueFilter?.toLowerCase()))
    );
  };

  const fetchMessagesWithFilter = async (messageFilter) => {
    // Clear messages (shows loading)
    setMessages(null);

    let results = allMessages;

    if (!results || messageFilter?.isRefresh) {
      results = await getMessages();
      setSearchValue("");
      setAllMessages(results);
    }

    if (!messageFilter?.isRefresh) {
      results = filterMessages(results, messageFilter?.searchValue || "", statusFilter);
    }

    setMessages(results);
    setNumberOfPages(numberOfPages);
    setFilter(filter);
    resetSelectedMessages();
  };

  const resetSelectedMessages = () => {
    setSelectedMessages([]);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleFilterCategory = (filter) => {
    const status = filterMap[filter.name];
    if (status !== undefined) {
      const filteredMessages = filterMessages(allMessages, searchValue, status);

      setFilter(filter);
      setStatusFilter(status);
      setMessages(filteredMessages);
    } else {
      fetchMessagesWithFilter(filter);
      setSearchValue("");
    }
  };

  const handleRefresh = () => {
    fetchMessagesWithFilter({ page: page, isRefresh: true });
  };

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo mensagem...");
      await commentUseCases.deleteComment(id);
      setMessages((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      handleApiErrors("Não é possível excluir uma mensagem respondida", error);
    } finally {
      openBackdrop(false);
    }
  };

  const handleApiErrors = (msg, error) => {
    setFeedbackSnackbar({
      isOpen: true,
      message: msg + error,
      type: "error",
    });
  };

  const handleSelectMessage = (id) => {
    if (selectedMessages.includes(id)) {
      const newSelectedMessages = [...selectedMessages].filter((messageId) => messageId !== id);
      setSelectedMessages(newSelectedMessages);
    } else {
      setSelectedMessages([...selectedMessages, id]);
    }
  };

  const CustomFilterList = () => (
    <FilterList items={filtersList} selectedItem={filter} onClickItem={handleFilterCategory} />
  );

  const handleClickMessage = (message) => {
    navigate(replyMessagePath + message.id);
  };

  useEffect(() => {
    windowWidth < 900 ? setMobile(true) : setMobile(false);
  }, [windowWidth]);

  return {
    mobile,
    selectedMessages,
    messages,
    setMessages,
    setSelectedMessages,
    handleDelete,
    handleRefresh,
    handleSearch,
    numberOfPages,
    fetchMessagesWithFilter,
    CustomFilterList,
    handleSelectMessage,
    searchValue,
    handleClickMessage,
    filter,
  };
};

export default MessagesController;
