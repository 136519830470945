import React, { useEffect, useState, useContext } from "react";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
// import EmbedVideo from "~/presentation/components/EmbedVideo/index";
import MostAccessedSection from "~/presentation/views/Blog/Post/Sections/MostAccessedSection";
import CommentsSection from "../../Sections/Comments";
import { makeBlogUseCase as blogUseCases } from "~/main/factories/use-cases/blog";
import AppContext from "~/presentation/AppContext";
import { Box, Typography as Text, Divider, Container, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BackButton from "~/presentation/components/BackButton/BackButton";

const PostContent = ({ post, handleComment, postComments, loading }) => {
  const navigate = useNavigate();
  const DATE = post?.publishDate ? new Date(post.publishDate) : new Date();
  const formattedDate = format(DATE, "dd/MM/yyyy");
  const [mostAccessedPosts, setMostAccessedPosts] = useState([]);
  const TELEGRAM_IMG = "/assets/canalTelegram.jpg";
  const { isLogged } = useContext(AppContext);

  const getAutorOfPost = () => {
    if (post?.autor) {
      return `${" "}Por: ${post.autor}${" "}`;
    }
  };

  const getMostAccessedPosts = async () => {
    const { posts } = await blogUseCases().getMostAccessedPosts(4);
    setMostAccessedPosts(posts);
  };

  const handleNavigateToPost = (id) => {
    if (isLogged()) {
      return navigate("/cliente/blog/post/" + id);
    }
  };

  useEffect(() => {
    getMostAccessedPosts();
  }, []);

  const decodeHtmlEntities = (text) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  let cleanedText = decodeHtmlEntities(post?.text);
  cleanedText = cleanedText.trim();
  cleanedText = capitalizeFirstLetter(cleanedText);

  return (
    <Container
      maxWidth="lg"
      sx={{
        marginBlock: 2,
        overflow: "hidden",
        width: "100%",
        maxWidth: "100vw",
      }}
    >
      <Grid container spacing={5}>
        <Grid size={{ xs: 12, lg: 9 }}>
          {loading ? (
            <Box sx={{ mt: 1, display: "flex", flexDirection: "column", gap: 2 }}>
              <Skeleton variant="rounded" height={30} width={120} />
              <Skeleton variant="rounded" height={100} />
              <Skeleton variant="rounded" height={40} />
              <Skeleton variant="rounded" height={400} />
              <Skeleton variant="rounded" height={600} />
            </Box>
          ) : (
            <>
              <BackButton />
              <Text sx={styles.title}>{post?.title}</Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: { md: 2, xs: 0 },
                  alignItems: { md: "center", xs: "flex-start" },
                }}
              >
                <Text sx={styles.subtitle}>
                  {formattedDate && `Publicado em: ${formattedDate}`}
                </Text>
                <Text sx={styles.authorName}>{getAutorOfPost()}</Text>
              </Box>

              <Divider sx={{ width: "40%", marginBlock: 1 }}></Divider>
              {post?.videoUrl && (
                <Box style={{ width: "100%", paddingBlock: 20, height: 583, maxWidth: 970 }}>
                  <iframe
                    src={post?.videoUrl}
                    title={post?.title}
                    width="100%"
                    height="100%"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    allowFullScreen
                  />
                </Box>
              )}
              {!post?.videoUrl && post?.image && (
                <Box component="img" sx={styles.img} src={post?.image} alt="post-image" />
              )}
              <Text sx={styles.contentText}>
                <span dangerouslySetInnerHTML={{ __html: cleanedText }}></span>
              </Text>
              <Text sx={[styles.title, { mt: 6 }]}>Deixe seu comentário</Text>
              <CommentsSection handleComment={handleComment} comments={postComments} />
            </>
          )}
        </Grid>
        <Grid
          size={{ xs: 12, lg: 3 }}
          sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: 2 }}
        >
          {loading && !mostAccessedPosts ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Skeleton variant="text" height={60} sx={{ marginTop: "auto" }} />
              <Skeleton variant="rounded" height={250} sx={{ borderRadius: "12px" }} />
              <Skeleton variant="rounded" height={250} sx={{ borderRadius: "12px" }} />
              <Skeleton variant="rounded" height={250} sx={{ borderRadius: "12px" }} />
            </Box>
          ) : (
            <>
              <MostAccessedSection
                mostAccessedPosts={mostAccessedPosts.slice(0, 3)}
                goToPost={(id) => handleNavigateToPost(id, undefined)}
                defaultImage={""}
              />
              <Box
                component="a"
                href="https://t.me/+j00d6m6nZ1tkMGYx"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "block",
                  cursor: "pointer",
                  margin: "0 auto 3rem auto",
                  width: "min(100%, 500px)",
                }}
              >
                <Box component="img" src={TELEGRAM_IMG} sx={styles.flyer} />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const styles = {
  title: {
    color: "#3A3A3A",
    fontSize: 28,
    fontFamily: "Montserrat",
    fontWeight: 600,
    paddingBlock: 2,
  },
  subtitle: {
    color: "#000",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "Montserrat",
    // marginTop: 2,
    // marginBottom: 1,
  },
  authorName: {
    color: "#000",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "Montserrat",
  },
  contentText: {
    fontFamily: "Montserrat",
    fontSize: 16,
    lineHeight: 1.6,
    textAlign: "justify",
    "&::first-letter": {
      textTransform: "uppercase",
    },
    // estilos da descrição HTML respeitando strongs do html
    "&& strong": {
      "font-weight": "bold",

      "& *": {
        "font-weight": "bold",
      },
    },
  },
  flyer: {
    justifySelf: "center",
    height: "100%",
    width: "100%",
    borderRadius: "6px",
    objectFit: "cover",
    transition: "transform 0.3s ease-out, background-color 0.3s ease-out",
    "&:hover": {
      transform: "scale(1.01)",
    },
  },
  img: {
    position: "relative",
    width: "100%",
    height: "auto",
    objectFit: "contain",
    borderRadius: "12px",
    marginBottom: "1.25rem",
    marginTop: "2rem",

    "@media (max-width: 65rem)": {
      marginTop: "2rem",
    },
  },
};

export default PostContent;
