import React from "react";
import { Box, Checkbox, FormControlLabel, Radio, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import Grid from "@mui/material/Grid2";

export const StatsForm = ({
  errors,
  values,
  onChangeValue,
  companiesData,
  usersData,
  handleSubmit,
  setStatsData,
}) => {
  const searchOptions = [
    { value: "search", label: "Pesquisas no GT-Fácil realizadas pela empresa" },
    { value: "search-summary", label: "Resumo das pesquisas realizadas no GT-Fácil" },
    { value: "content-access", label: "Conteúdos acessados pela empresa" },
  ];

  const subContainer = {
    backgroundColor: "#E6E6E6",
    borderRadius: "12px",
    padding: 3,
    mb: 1,
  };

  return (
    <>
      <Grid container spacing={1} sx={[subContainer]}>
        <Grid size={12}>
          <Typography sx={formStyles.title}>Tipo de busca</Typography>
        </Grid>
        {searchOptions.map((type, index) => (
          <Grid key={index} size={{ xs: 12, md: 4 }}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Radio
                  sx={formStyles.icon}
                  checked={type.value === values?.searchType}
                  onChange={() => {
                    onChangeValue("searchType", type.value);
                    setStatsData([]);
                  }}
                  name={type.value}
                />
              }
              label={type.label}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1} sx={[subContainer, { flex: 1 }]}>
        <Grid size={12}>
          <Typography sx={formStyles.title}>Filtros</Typography>
        </Grid>
        <Grid size={{ xs: 12, lg: 6, xl: 3 }}>
          <Input
            type="select"
            title="Empresa"
            name="companyId"
            placeholder="Selecione a empresa"
            value={
              values?.companyId ? companiesData.find((item) => item.id === values?.companyId) : null
            }
            onChange={(option) => onChangeValue("companyId", option.id)}
            options={companiesData}
            error={errors?.companyId}
          />
        </Grid>
        {values.searchType === "search" && (
          <>
            <Grid size={{ xs: 12, lg: 6, xl: 3 }}>
              <Input
                type="select"
                title="Usuário"
                name="userId"
                value={
                  values?.userId
                    ? usersData?.users?.find((item) => item.id === values?.userId)
                    : null
                }
                onChange={(option) => onChangeValue("userId", option.id)}
                options={usersData?.users}
                disabled={usersData?.loading}
                error={errors?.userId}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, xl: 3 }}>
              <Input
                type="date"
                title="Data Inicial"
                name="initialDate"
                value={values?.initialDate}
                onChange={(date) => onChangeValue("initialDate", date)}
                error={errors?.initialDate}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, xl: 3 }}>
              <Input
                type="date"
                title="Data Final"
                name="finalDate"
                value={values?.finalDate}
                onChange={(date) => onChangeValue("finalDate", date)}
                error={errors?.finalDate}
              />
            </Grid>
          </>
        )}
        {values.searchType === "content-access" && (
          <Grid size={{ xs: 12, sm: "auto" }} sx={[formStyles.checkBoxContainer, { pt: 2 }]}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values?.isDetail || null}
                  onChange={() => onChangeValue("isDetail", !values?.isDetail)}
                  name="isDetail"
                />
              }
              label="Exibir conteúdo detalhado"
            />
          </Grid>
        )}
      </Grid>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Pesquisar
        </ButtonCommon>
      </Box>
    </>
  );
};
