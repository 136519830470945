import React from "react";
import { Box, Typography } from "@mui/material";
import Table from "~/presentation/components/Table";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ExportFilter from "./exportFilter/ExportFilter";

const statsTableTitle = {
  search: "Pesquisas no GT-Fácil realizadas pela empresa",
  "search-summary": "Resumo das pesquisas realizadas no GT-Fácil",
  "content-access": "Conteúdos acessados pela empresa",
};

const StatsTable = ({ tableRef, columns = [], data = [], searchType = "" }) => {
  const dataToTable = data.length > 0 ? data.map((item, index) => ({ ...item, id: index })) : [];
  return (
    <>
      <Box sx={styles.headerTable}>
        <Typography sx={formStyles.title}>{statsTableTitle[searchType]}</Typography>
      </Box>
      <Box ref={tableRef}>
        <Table
          columns={columns}
          data={dataToTable}
          tableExport={false}
          customToolbarExtraComponents={[
            (index) => (
              <ExportFilter key={index} data={data} columns={columns} searchType={searchType} />
            ),
          ]}
        />
      </Box>
    </>
  );
};

export default StatsTable;

const styles = {
  headerTable: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    paddingBottom: 3,
    "@media (min-width: 1280px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
};
