import React from "react";
import { List, ListItem, ListItemText, Box, Typography, Checkbox, Skeleton } from "@mui/material";
import { format } from "date-fns";
import parse from "html-react-parser";
import { formStyles } from "~/presentation/components/Styles/formStyles";

const MessageInfoList = ({
  messages,
  selectedMessages,
  handleSelectMessage,
  disableGutters, // if true, disable the padding of ListItem components (used to free space on mobile views)
  onClick,
  rightSmall = "date",
  noMessagesText = "Não há mensagens",
}) => {
  const isMessageSelected = (messageId) =>
    selectedMessages.map((msg) => msg.id).includes(messageId);

  return (
    <List disablePadding={disableGutters} sx={{ pb: 2, flex: 1, overflowY: "auto" }}>
      {!messages ? (
        [1, 2, 3, 4, 5, 6, 7].map((i) => (
          <Skeleton variant="rounded" height={72} sx={{ my: 1 }} key={i} width="100%" />
        ))
      ) : !messages.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            width: "100%",
          }}
        >
          {noMessagesText}
        </Box>
      ) : (
        messages.map((message) => (
          <ListItem
            key={message.id}
            disableGutters={disableGutters}
            button
            sx={{ borderRadius: "12px", pl: 2 }}
          >
            <Checkbox
              edge="start"
              checked={isMessageSelected(message.id)}
              tabIndex={-1}
              disableRipple
              onClick={() => handleSelectMessage(message)}
              inputProps={{ "aria-labelledby": `checkbox-list-label-${message.id}` }}
              sx={formStyles.icon}
            />
            <ListItemText
              onClick={() => onClick(message)}
              primary={parse(message.subject)}
              secondary={`${message.email} - ${format(
                new Date(message.publishDate),
                "dd/MM/yyyy"
              )}`}
              sx={{ ...formStyles.title, textTransform: "none", cursor: "pointer", pl: 1 }}
            />
            <Box
              component="span"
              sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", ml: 2 }}
            >
              <Typography variant="caption">{message[rightSmall]}</Typography>
            </Box>
          </ListItem>
        ))
      )}
    </List>
  );
};

export default MessageInfoList;
