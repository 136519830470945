import React, { useState, useContext, useEffect } from "react";
import AppContext from "~/presentation/AppContext";
import { AccountCircleOutlined, Lock as LockIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import Shake from "~/presentation/components/Animation/Shake/Shake";

const StyledForm = styled("div")(() => ({
  width: "100%",
  marginTop: 1,
}));

const StyledOutlinedInput = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px transparent inset",
    },
    "& fieldset": {
      borderColor: "#14213D",
    },
    "&:hover": {
      borderColor: "#14213D",
    },
    "&:focused": {
      borderColor: "#14213D",
    },
  },
}));

const LoginForm = ({ handleClose, navigate, currentModal, setCurrentModal, isReLogin }) => {
  const { handleLogin, setDidLogin, user, handleLogout } = useContext(AppContext);

  const [touch, setTouch] = useState({
    email: false,
    password: false,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const [values, setValues] = useState({
    email: currentModal?.content?.email,
    password: "",
  });

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const test = emailRegex.test(value);
    return test;
  };

  const validatePassword = () => {
    const { password } = values;
    if (!password || password === "") return false;

    return true;
  };

  const handleSubmit = async () => {
    if (!validateEmail(values.email)) return;

    if (!validatePassword()) {
      if (!touch.password) {
        setTouch({
          ...touch,
          password: true,
        });
      }
      return;
    }

    try {
      setLoading(true);
      setError(null);

      await handleLogin(values);
      setDidLogin((prev) => !prev);

      const user = JSON.parse(localStorage.getItem("GT_user"));

      if (!user) return;

      if (isReLogin) return handleClose();

      navigate("/cliente");

      handleClose();
      window.location.reload();
    } catch (error) {
      setError(error.data);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleTouch = ({ target }) => {
    const { name } = target;
    setTouch({
      ...touch,
      [name]: true,
    });
  };

  useEffect(() => {
    if (isReLogin) {
      setValues({
        email: user.email,
        password: "",
      });
    }
  }, [isReLogin]);

  const passwordError = !validatePassword();
  const emailError = !validateEmail(values.email);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleForgetPassword = () => {
    if (emailError) return;

    setCurrentModal({
      name: "forget-password",
      content: {
        email: values.email,
      },
    });
  };

  return (
    <StyledForm>
      {isReLogin && (
        <Typography sx={{ ...styles.text, ...styles.message }}>
          <span>Sua sessão expirou!</span> <br /> Por favor, faça o login.
        </Typography>
      )}
      <StyledOutlinedInput
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={handleChange}
        onBlur={handleTouch}
        error={emailError}
        helperText={touch.email && emailError ? "Verifique o email digitado." : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleOutlined style={{ color: "#14213D" }} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: "#14213D", fontFamily: "Montserrat" },
        }}
        value={values.email}
        disabled={isReLogin}
      />
      <StyledOutlinedInput
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Senha"
        onChange={handleChange}
        onBlur={handleTouch}
        onKeyDown={handleKeyDown}
        type="password"
        id="password"
        autoComplete="current-password"
        error={passwordError && touch.password}
        helperText={touch.password && passwordError ? "Verifique a senha digitada." : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon style={{ color: "#14213D" }} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: "#14213D", fontFamily: "Montserrat" },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <>
          {isReLogin && (
            <Typography
              onClick={() => {
                handleLogout();
                handleClose();
              }}
              variant="outlined"
              color="error"
              sx={{
                ...styles.text,
                cursor: "pointer",
                color: "red",
              }}
            >
              Logout
            </Typography>
          )}
          <Typography
            onClick={handleForgetPassword}
            id="forgotPass"
            sx={{
              ...styles.text,
              cursor: "pointer",
              marginLeft: "auto",
              color: emailError ? "#CDCDCD" : "#000",
            }}
          >
            Esqueci a senha
          </Typography>
        </>
      </Box>
      {error && (
        <Shake trigger={error}>
          <Typography sx={{ ...styles.text, color: "red", marginTop: "0.5rem" }}>
            {error}
          </Typography>
        </Shake>
      )}
      <Button
        onClick={handleSubmit}
        fullWidth
        variant="contained"
        style={{
          marginTop: "1rem",
          background: "linear-gradient(180deg, #1341a1 0%, #102342 100%",
          color: "#fff",
          fontFamily: "Montserrat",
        }}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Entrar"}
      </Button>
    </StyledForm>
  );
};

export default LoginForm;

const styles = {
  text: {
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  message: {
    textAlign: "center",
    padding: "2px",
    "> span": {
      color: "#1341a1",
      fontSize: 16,
      fontWeight: 700,
    },
  },
};
