import React, { useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import StatsTable from "~/presentation/views/Admin/Stats/Display/StatsTable";
import { statsTableColumns } from "~/presentation/views/Admin/Stats/Display/statsTableColumns";
import { StatsForm } from "~/presentation/views/Admin/Stats/Display/StatsForm";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import PageListHeader from "../../components/PageListHeader";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { statsFormValidationSchema } from "./statsFormValidationSchema";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

const Stats = ({ statsUseCases, companyUseCases, userUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));

  const [companiesData, setCompaniesData] = useState([]);
  const [usersData, setUsersData] = useState({ loading: false, users: [] });
  const [statsData, setStatsData] = useState([]);
  const tableRef = useRef(null);

  const [hasSearching, setHasSearching] = useState(false);

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    searchType: "search",
    companyId: companyId || null,
    initialDate: new Date(new Date().getFullYear(), 0, 1),
    finalDate: new Date(),
    userId: null,
    isDetail: false,
  });

  useEffect(() => {
    setHasSearching(false);
  }, [values?.searchType]);

  const handleChangeValue = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    openBackdrop(true, "Carregando");
    try {
      const response = await companyUseCases.getCompanies({
        isSummary: true,
        checkPermission: false,
      });
      setCompaniesData(dataToSelectMapper(response));
    } catch (error) {
      showError(error, "Erro ao carregar as empresas.");
    }
    openBackdrop(false);
  };

  useEffect(() => {
    if (values.companyId) {
      getUsers(values.companyId);
    }
  }, [values.companyId]);

  const getUsers = async (id) => {
    setUsersData((prev) => ({ ...prev, loading: true }));
    handleChangeValue("userId", null);
    try {
      const response = await userUseCases.getUsers({
        checkPermission: false,
        companyId: id,
      });
      setUsersData((prev) => ({
        ...prev,
        users: dataToSelectMapper([{ value: null, name: "Remover filtro usuário" }, ...response]),
      }));
    } catch (error) {
      showError(error, "Erro ao carregar usuários da empresa.");
    } finally {
      setUsersData((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleValidation = (values) => {
    statsFormValidationSchema
      .validate(values, { abortEarly: false })
      .then(async () => handleSubmit(values))
      .catch((errors) => {
        setErrors(
          errors?.inner?.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Pesquisando");
    setHasSearching(true);

    const submitValues = {
      companyId: values?.companyId,
      searchType: values?.searchType,
      userId: values?.userId,
      initialDate: values?.initialDate.toISOString().split("T")[0],
      finalDate: values?.finalDate.toISOString().split("T")[0],
      isDetail: values?.isDetail,
    };

    try {
      switch (values.searchType) {
        case "search":
          setStatsData(
            await statsUseCases.getStatsSearch({
              companyId: submitValues.companyId,
              userId: submitValues.userId,
              DateStart: submitValues.initialDate,
              DateEnd: submitValues.finalDate,
            })
          );
          break;

        case "search-summary":
          setStatsData(
            await statsUseCases.getStatsSearchSummary({ companyId: submitValues.companyId })
          );
          break;

        case "content-access":
          setStatsData(
            await statsUseCases.getStatsContentAccess({
              companyId: submitValues.companyId,
              isDetail: submitValues.isDetail,
            })
          );
          break;

        default:
          break;
      }

      tableRef.current.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      showError(error, "Erro durante a pesquisa");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader title="Visualizações" />
      <Box>
        <Box sx={{ m: { xs: 1, md: 3 } }}>
          <StatsForm
            companiesData={companiesData}
            usersData={usersData}
            handleSubmit={handleValidation}
            setStatsData={setStatsData}
            errors={errors}
            values={values}
            onChangeValue={handleChangeValue}
          />
        </Box>
        {hasSearching && (
          <Box sx={[formStyles.container, { minHeight: 700 }]}>
            <StatsTable
              tableRef={tableRef}
              data={statsData}
              columns={statsTableColumns[values.searchType]}
              searchType={values.searchType}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Stats;
