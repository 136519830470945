import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";

const ValueVariable = ({ valueVariableUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //Data
  const [valueVariables, setValueVariables] = useState();

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "md",
      fullWidth: true,
      customComponent: () => (
        <CreateEdit data={data} onCloseForm={closeDialog} refreshData={fetchData} />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const apiValueVariables = await valueVariableUseCases.getAll();
      setValueVariables(apiValueVariables);
    } catch (error) {
      showError(error, "Erro ao carregar variáveis de valor");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "code", headerName: "Código", align: "center" },
    { field: "value", headerName: "Valor", flex: 1, align: "center" },
    { field: "name", headerName: "Nome", flex: 2 },
    {
      name: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          editSecurePath={[adminPerms.editValueVariables]}
          handleDelete={() => deleteDialog(params.row.id)}
          deleteSecurePath={[adminPerms.deleteValueVariables]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo Variável de Valor");
    try {
      await valueVariableUseCases.delete(id);
      showSuccess("Variável de Valor excluída com sucesso!");
      setValueVariables((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir Variável de Valor");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta variável de valor?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageListHeader
        title="Variável de Valor"
        titleAccess="Nova variável"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addValueVariables]}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={valueVariables} />
      </Box>
    </>
  );
};

export default ValueVariable;
