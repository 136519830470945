import React from "react";
import { BrowserRouter as Router, Routes as RoutesContainer, Route } from "react-router-dom";
import PrivateRoute from "~/presentation/routes/PrivateRoute";
import AdminRoutes from "~/presentation/routes/AdminRoutes";
import LandingPageRoutes from "~/presentation/routes/LandingPageRoutes";
import ClientRoutes from "~/presentation/routes/ClientRoutes";
import GTFacilRoutes from "~/presentation/routes/GTFacilRoutes";
import ReinfRoutes from "~/presentation/routes/ReinfRoutes";
import { SecurityRoute } from "../security/SecurityPath";
import { clientPerms } from "../security/SecurePaths";

const Routes = () => (
  <Router>
    <RoutesContainer>
      <Route
        path="/cliente/*"
        element={
          <PrivateRoute>
            <ClientRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path="/gt-facil/*"
        element={
          <PrivateRoute>
            <SecurityRoute
              securePaths={[
                clientPerms.seeCnaeResearch,
                clientPerms.seeLcResearch,
                clientPerms.seeFreeSearch,
              ]}
              returnTo="/cliente"
            >
              <GTFacilRoutes />
            </SecurityRoute>
          </PrivateRoute>
        }
      />

      <Route
        path="/admin/*"
        element={
          <PrivateRoute>
            <SecurityRoute secureMainRoute="admin" returnTo="/cliente">
              <AdminRoutes />
            </SecurityRoute>
          </PrivateRoute>
        }
      />

      <Route
        path="/reinf/*"
        element={
          <PrivateRoute>
            <SecurityRoute secureMainRoute="reinf" returnTo="/cliente">
              <ReinfRoutes />
            </SecurityRoute>
          </PrivateRoute>
        }
      />

      <Route path="/" element={<LandingPageRoutes />} />
      <Route path="*" element={() => <h1>404 Not found</h1>} />
    </RoutesContainer>
  </Router>
);

export default Routes;
