import React, { useState, useContext } from "react";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { Typography as Text, IconButton } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Close } from "@mui/icons-material";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import Grid from "@mui/material/Grid2";

export const SendR9000 = ({
  nfseDate,
  DigitalCertificate,
  UserCompany,
  CompanyUserId,
  competence,
  selectedYear,
  selectedMonth,
  closeModal,
  openDetailsModal,
}) => {
  const { showError } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const apiSendReinf = new ApiReinfUseCases(makeHttpClient());
  const [eventData, setEventData] = useState({
    companyId: CompanyUserId,
    digitalCertId: DigitalCertificate?.id,
    isProduction: true,
    calculationDate: competence,
  });

  const getNumberOfEvent = (event) => {
    const getNumber = {
      R2010: 3,
      R2020: 4,
      R2070: 5,
      R4010: 9,
      R4020: 10,
      R4040: 11,
      R4080: 12,
    };
    return getNumber[event];
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : "";
    setEventData((prev) => ({ ...prev, [name]: value }));
  };

  const isProductionOptions = [
    { value: false, label: "Produção Restrita" },
    { value: true, label: "Produção" },
  ];

  const handleSubmit = async () => {
    const updatedEventData = {
      ...eventData,
      infoEvents: nfseDate.map((item) => {
        return { event: getNumberOfEvent(item.reinfEvent), receiptNumber: item.receiptNumber };
      }),
    };

    openBackdrop(true, "Enviando dados do Evento R-9000");
    try {
      const response = await apiSendReinf.sendEvent(updatedEventData, "r9000");
      if (response) openDetailsModal(response);
    } catch (error) {
      console.error("Erro ao enviar o evento R-9000: ", error);
      showError(error, "Erro");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid size={{ xs: 6, sm: 11 }}>
          <Text style={styles.Title}>DADOS DO EVENTO R-9000</Text>
        </Grid>
        <Grid
          size={{ xs: 6, sm: 1 }}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <IconButton>
            <Close onClick={closeModal} />
          </IconButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Estabelecimento"
            name="companyName"
            value={UserCompany?.name || ""}
            disabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Certificado Digital"
            name="digitalCertId"
            value={DigitalCertificate?.name || ""}
            disabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Data da competência"
            value={`${selectedMonth}/${selectedYear}`}
            disabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="select"
            title="Tipo de ambiente"
            name="isProduction"
            options={isProductionOptions}
            onChange={(option) => handleSelectChange(option, { name: "isProduction" })}
            value={isProductionOptions.find((option) => option.value === eventData?.isProduction)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Text sx={{ fontSize: 14, maxWidth: "300px", marginTop: 3 }}>
            {nfseDate.length === 1
              ? `O evento selecionado será excluído ao enviar o evento R-9000.`
              : `Os ${nfseDate.length} eventos selecionados serão excluídos ao enviar o evento R-9000.`}
          </Text>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }} sx={{ display: "flex", gap: 1, marginTop: 3 }}>
          <RButton type="button" variant="outlined" onClick={closeModal}>
            Cancelar
          </RButton>
          <RButton variant="contained" color="primary" onClick={handleSubmit}>
            Enviar
          </RButton>
        </Grid>
      </Grid>
    </>
  );
};
const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
  subTitle: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
};
