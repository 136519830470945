import { Box } from "@mui/material";
import React from "react";

function ImageCarousel({
  content = [],
  imageStyles = { width: 200 },
  containerStyles = { width: "100%", height: "200px" },
  animationTime = "40s",
}) {
  if (content.length === 0) return null;
  const imageWidth =
    imageStyles.width && typeof imageStyles?.width === "number"
      ? imageStyles.width
      : imageStyles.width && Number(imageStyles.width.replace("px", ""));
  const totalImagesWidth = content.length * imageWidth;

  return (
    <>
      <Box
        sx={{
          ...containerStyles,
          position: "relative",
          overflowX: "hidden",
          maskImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%)",
          WebkitMaskImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            left: 0,
            animation: `carousel ${animationTime} linear infinite`,
          }}
        >
          {[...content, ...content, ...content].map((l, index) => (
            <Box
              component="img"
              src={`/assets/clients_logo/${l}`}
              alt={l}
              key={"landing-page-clients-carousel-image-" + index}
              sx={{
                height: "auto",
                objectFit: "contain",
                margin: "0px 20px",
                width: imageWidth,
              }}
              draggable={false}
            />
          ))}
        </Box>
      </Box>
      <style>
        {`
            @keyframes carousel {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-${totalImagesWidth}px);
                }
            }
        `}
      </style>
    </>
  );
}

export default ImageCarousel;
