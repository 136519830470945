import React, { createContext, useMemo, useState } from "react";
import FeedbackSnackbar from "~/presentation/components/FeedbackSnackbar";

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    isOpen: false, //sempre enviamos como {isOpen: true} para chamar.
    message: "Message!", //mensagem de texto
    type: "warning", //info, warning, error, success
  });

  const openSnackbar = ({ message, type, isOpen }) => {
    setSnackbar({ isOpen, message, type });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, isOpen: false });
  };

  //Ver se o erro está em Português-Brasil
  const isErrorMessageInPTBR = (message) => {
    if (!message || typeof message !== "string") return false;

    const apiEnglishWords = [
      "Request",
      "status",
      "code",
      "Error",
      "AxiosError",
      "Axios",
      "Object",
      "Reference",
    ];

    return !apiEnglishWords.some((word) => message.includes(word));
  };

  const showError = (apiErrorMsg, frontErrorMsg = "Erro:") => {
    let errorMsg = "";

    if (isErrorMessageInPTBR(apiErrorMsg?.response?.data)) {
      errorMsg = frontErrorMsg + ": " + apiErrorMsg?.response?.data;
    } else if (isErrorMessageInPTBR(apiErrorMsg?.message)) {
      errorMsg = frontErrorMsg + ": " + apiErrorMsg?.message;
    } else if (isErrorMessageInPTBR(apiErrorMsg?.data)) {
      errorMsg = frontErrorMsg + ": " + apiErrorMsg?.data;
    } else {
      errorMsg = frontErrorMsg;
    }

    setSnackbar({
      isOpen: true,
      message: errorMsg,
      type: "error",
    });
  };

  const showSuccess = (message = "") => {
    setSnackbar({
      isOpen: true,
      message: message,
      type: "success",
    });
  };

  const showInfo = (message = "") => {
    setSnackbar({
      isOpen: true,
      message: message,
      type: "info",
    });
  };

  const value = {
    setFeedbackSnackbar: openSnackbar,
    closeSnackbar,
    showError,
    showSuccess,
    showInfo,
  };

  return (
    <SnackbarContext.Provider value={useMemo(() => value, [])}>
      {children}
      <FeedbackSnackbar
        isOpen={snackbar.isOpen}
        message={snackbar.message}
        type={snackbar.type}
        onClose={closeSnackbar}
      />
    </SnackbarContext.Provider>
  );
};
