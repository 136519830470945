import React, { useState } from "react";
import { FiberManualRecord } from "@mui/icons-material";
import { Button, IconButton, Popover } from "@mui/material";

/**
 * Popover component, receives an icon and children to be rendered
 * inside of the Paper.
 */

const OpenPopover = ({ children: Children, icon: Icon, label }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {label ? (
        <Button
          sx={{
            textTransform: "none",
            color: "#fff",
            backgroundColor: "#001A50",
            borderRadius: "12px",
            width: "min(300px,100%)",

            ".MuiButton-label": {
              display: "grid",
              gridTemplateColumns: Icon ? "auto auto" : "auto",
            },
            gap: "8px",

            span: {
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
          }}
          aria-describedby={id}
          onClick={handleClick}
        >
          {Icon && <Icon />}
          {/* {Icon ? <Icon /> : <FiberManualRecordIcon />} */}
          <span>{label}</span>
        </Button>
      ) : (
        <IconButton aria-describedby={id} onClick={handleClick}>
          {Icon ? <Icon /> : <FiberManualRecord />}
        </IconButton>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          background: "none",
          color: "#222222",
          boxShadow: "#CDCDCD",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Children />
      </Popover>
    </>
  );
};

export default OpenPopover;
