import React, { useState, useEffect, useContext, useRef } from "react";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import {
  formatDateToBrazilian,
  formatDateFilter,
} from "~/presentation/views/Reinf/Utils/utilsDate";
import { useTableFilters } from "../../Hooks/useTableFilters";
import Filters from "./Filters/Filters.jsx";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import DocTable from "./DocTable";
import { ApiPaymentRecordUseCases } from "~/data/usecases/paymentRecord/apiPaymentRecord";
import PaymentTable from "./PaymentTable";
import useHandleDocFiscais from "../../Hooks/useHandleDocFiscais";

export const TableNfse = ({ importRefresh }) => {
  const apiNfse = new ApiNfseUseCases(makeHttpClient());
  const apiPayment = new ApiPaymentRecordUseCases(makeHttpClient());
  const { docSituationFilter, docTypeFilter, handleSearchTable } = useTableFilters(); // HOOK FILTROS
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const { getNewItemsNFse, handleChangedNfse } = useHandleDocFiscais();

  //DATA
  const [nfseChanged, setNfseChanged] = useState([]);
  const [nfseData, setNfseData] = useState([]);
  const [currentNfseData, setCurrentNfseData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [filteredPaymentData, setFilteredPaymentData] = useState([]);

  //ERROS CAMPOS
  const [errors, setErrors] = useState({});

  //FILTRO DE PESQUISA
  const [searchField, setSearchField] = useState("");

  // FILTROS - PADRÃO NOTAS ÚLTIMOS 6 MESES + data de emissão
  const today = new Date();
  const initalMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));
  const [orderBy, setOrderBy] = useState("dateOfIssue");
  const [initialDate, setInitialDate] = useState(initalMonth);
  const [finalDate, setFinalDate] = useState(today);
  const [documentSituation, setDocumentSituation] = useState("");
  const [documentType, setDocumentType] = useState(0);

  const [openPersonsTable, setOpenPersonsTable] = useState(false);

  //ERRO: initialDate não pode ser maior do que final e vice-versa.
  const isDateInTheFuture = initialDate > new Date() || finalDate > new Date();
  const isDateWrong = initialDate > finalDate;

  useEffect(() => {
    fetchDataIntoState(paramsDate);
  }, [importRefresh]);

  // ⇣⇣⇣ Cons para ver se o período de datas se modifica
  const [hasDateChanged, setHasDateChanged] = useState(1);
  const hasDateChangedRef = useRef(0);
  // ⇣⇣⇣ caso a ordem, ou alguma data mude aumenta um no hasDataChangedRef
  // ⇣⇣⇣ e no handleFilters ele compara o ref com o state, caso seja igual não dá fecth à API
  // ⇣⇣⇣ caso seja diferente, quer dizer que as datas alterarão e aí sim dá novo fetch à API
  useEffect(() => {
    hasDateChangedRef.current += 1;
  }, [initialDate, finalDate, orderBy]);

  // ⇣⇣⇣ PARAMS para o fetch
  const params = {
    start: formatDateFilter(initialDate),
    end: formatDateFilter(finalDate),
    isDateOfPayment: orderBy === "dateOfPayment" ? true : false,
  };
  // ⇣⇣⇣ caso não haja datas quero que vá null para não dar erro
  const paramsDate = !params.start || !params.end ? null : params;

  const loadData = async (data) => {
    try {
      openBackdrop(true, "Carregando dados de Doc.Fiscais");
      const response = await apiNfse.getNfse(data);
      if (response.data) {
        const apiNfseData = response.data;
        const data = apiNfseData.map((nfse) => ({
          ...nfse,
          itemsNFse: getNewItemsNFse(nfse.itemsNFse), //hook para atribuir corretamente o itemnNFSE a cada nota, se necessário
        }));
        return data;
      }
    } catch (error) {
      showError(error, "Erro ao carregar documentos fiscais");
    } finally {
      setOpenPersonsTable(false);
      openBackdrop(false);
    }
  };

  const loadPaymentData = async () => {
    try {
      const payments = await apiPayment.getPaymentRecord(paramsDate);
      if (payments.data) {
        setPaymentData(payments.data);
      }
    } catch (error) {
      showError(error, "Erro no retorno dos dados de pagamento");
    } finally {
      openBackdrop(false);
    }
  };

  const fetchDataIntoState = async (data) => {
    const result = await loadData(data);
    setNfseData(result);
    setCurrentNfseData(result);
  };

  const handleFilters = async () => {
    setOpenPersonsTable(false);

    // ⇣⇣⇣ caso data seja errada não permite o fetch
    if (isDateWrong || isDateInTheFuture) {
      showError({}, "Período de datas incorreto!");
      return;
    }

    // para filtrar por pagamento é preciso ter selecionado um período de datas
    if ((!initialDate || !finalDate) && orderBy === "dateOfPayment") {
      showError({}, "Escolha um período de datas para filtrar!");
      return;
    }

    let result = nfseData;

    // ⇣⇣⇣ Caso não seja alterado o periodo de datas o fetch não é chamado e só é filtrada a data da tabela
    if (hasDateChangedRef.current !== hasDateChanged) {
      setHasDateChanged(hasDateChangedRef.current);
      result = await loadData(paramsDate);
      setNfseData(result);
    }

    if (documentSituation) {
      result = docSituationFilter(result, documentSituation);
    }

    //não pode só ver se é true porque 0 é valor válido
    if ([0, 1, 2, 3, 4, 98, 99].includes(documentType)) {
      result = docTypeFilter(result, "typeDoc", documentType);
    }

    setCurrentNfseData(result);
  };

  const handleSearchPayment = () => {
    // Verifique se as datas são válidas
    if (isDateWrong || isDateInTheFuture) {
      showError({}, "Período de datas incorreto!");
      return;
    }
    let filteredData = paymentData;

    // se as datas forem fornecidas, filtrar
    if (initialDate && finalDate) {
      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.datePayment);
        return itemDate >= initialDate && itemDate <= finalDate;
      });
    }
    if (orderBy) {
      // por ordem
      filteredData = filteredData.sort((a, b) => {
        if (orderBy === "dateOfPayment") {
          return new Date(a.datePayment) - new Date(b.datePayment);
        }
        return new Date(a.dateOfIssue) - new Date(b.dateOfIssue);
      });
    }
    setFilteredPaymentData(filteredData);
    setOpenPersonsTable(true);
  };

  const handleDeleteNfse = (nfseId) => {
    openDialogBox({
      message: `Deseja eliminar permanentemente este documento?`,
      callback: async () => {
        try {
          openBackdrop(true, "Eliminando documento");
          await apiNfse.deleteNfse(nfseId);
          setCurrentNfseData((prev) => prev.filter((nfse) => nfse.id !== nfseId));
          setNfseData((prev) => prev.filter((nfse) => nfse.id !== nfseId));
        } catch (error) {
          showError(error, "Erro ao eliminar documento");
        } finally {
          openBackdrop(false);
        }
      },
    });
  };

  const handleChangeFieldValue = (props) => {
    const { newItem, id, byPass } = props;
    //byPass é para as contas automaticas não darem à nota o estado de alterada
    setCurrentNfseData((prev) => {
      const index = prev.findIndex((item) => item.id === id);
      if (index !== -1) {
        let updatedItems = [...prev];
        updatedItems[index] = {
          ...currentNfseData[index],
          ...newItem,
        };
        return updatedItems;
      } else {
        return prev;
      }
    });
    if (!byPass) {
      handleNfseChanges(id, newItem);
    }
  };

  const handleChangeINSSfield = (props) => {
    const { newItem, id, nestedIndex, byPass } = props;
    //byPass é para as contas automaticas não darem à nota o estado de alterada
    setCurrentNfseData((prev) => {
      const parentIndex = currentNfseData.findIndex((item) => item.id === id);
      if (parentIndex !== -1) {
        let updatedNfse = { ...currentNfseData[parentIndex] };
        const updatedItemINSS = [...updatedNfse.itemsNFse];
        updatedItemINSS.splice(nestedIndex, 1, newItem);
        if (!byPass) {
          handleNfseChanges(id, { itemsNFse: updatedItemINSS });
        }
        updatedNfse.itemsNFse = updatedItemINSS;
        let updatedItems = [...prev];
        updatedItems[parentIndex] = updatedNfse;
        return updatedItems;
      } else {
        return prev;
      }
    });
  };

  const handleNfseChanges = (id, item) => {
    //Enviar para o state de notas alteradas o id e os campos alterados com seus devidos valores
    setNfseChanged((prev) => {
      const index = prev?.findIndex((key) => key.id === id);
      if (index !== -1) {
        return prev.map((entry, i) => (i === index ? { ...entry, ...item } : entry));
      }
      return [...prev, { id, ...item }];
    });
  };

  useEffect(() => {
    const handleChange = (id, isEqual = false) => {
      handleChangeFieldValue({
        newItem: { hadChanges: isEqual },
        id: id,
        byPass: true,
      });
    };

    //HOOK que lida com as notas que foram alteradas e verifica o seu estado
    handleChangedNfse({
      changedNfse: nfseChanged,
      allNfse: nfseData,
      setChanged: (id) => handleChange(id, true),
      setEqual: (id) => handleChange(id, false),
    });
  }, [nfseChanged]);

  const handleSearch = (value) => {
    setSearchField(value);
    let searchKeys = ["businessPartnerCNPJ", "businessPartnerName", "amountIR", "contractorCNPJ"];
    if (documentType !== 98) {
      searchKeys.push(
        "nFseNumber",
        "dateOfIssue",
        "dateOfPayment",
        "amountOperation",
        "amountINSS",
        "amountAggregate",
        "amountCSRF",
        "typeDoc"
      );
      handleSearchTable(value, nfseData, setCurrentNfseData, searchKeys);
    } else {
      searchKeys.push("competence", "datePayment", "totalAmount", "taxedAmount");
      handleSearchTable(value, paymentData, setFilteredPaymentData, searchKeys);
    }
  };

  return (
    <>
      <Filters
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        documentSituation={documentSituation}
        setDocumentSituation={setDocumentSituation}
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
        formatDateToBrazilian={formatDateToBrazilian}
        searchField={searchField}
        handleSearch={handleSearch}
        handleFilters={handleFilters}
        setCurrentNfseData={setCurrentNfseData}
        nfseData={nfseData}
        fetchDataIntoState={fetchDataIntoState}
        documentType={documentType}
        setDocumentType={setDocumentType}
        handleSearchPayment={handleSearchPayment}
      />
      {!openPersonsTable ? (
        <DocTable
          data={currentNfseData}
          onDelete={handleDeleteNfse}
          handleChangeFieldValue={handleChangeFieldValue}
          handleChangeINSSfield={handleChangeINSSfield}
          errors={errors}
          setErrors={setErrors}
          setCurrentNfseData={setCurrentNfseData}
        />
      ) : (
        <PaymentTable
          data={filteredPaymentData}
          setData={setFilteredPaymentData}
          reloadPayData={loadPaymentData}
        />
      )}
    </>
  );
};
