import React, { useContext, useEffect, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import { makeItemAnswerUseCases } from "~/main/factories/use-cases/item-answer";
import Grid from "@mui/material/Grid2";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import TextEditor from "~/presentation/components/TextEditor";
import validationSchema from "~/presentation/views/Admin/ItemAnswer/CreateEdit/Form/validationSchema";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { adminPerms } from "~/presentation/security/SecurePaths";
import CreateEditType from "~/presentation/views/Admin/TypeAnswer/CreateEdit/CreateEdit";
import TableActions from "~/presentation/components/TableActions";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import CreateEditValue from "~/presentation/views/Admin/ValueVariable/CreateEdit/CreateEdit";

function ItemAcordion({
  item,
  typesAnswer = [],
  getTypesAnswer,
  handleDelete,
  noItemsToShow = false,
  fetchItemAnswer,
}) {
  const { openDialogBox, closeDialog } = useContext(DialogContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const itemAnswerUseCases = makeItemAnswerUseCases();
  const itemAnswerValidation = validationSchema;
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [itemValues, setItemValues] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setItemValues((prev) => ({ ...prev, [field]: value }));
    if (field) clearError(field);
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const fetchItem = async (id) => {
    setLoading(true);
    try {
      const result = await itemAnswerUseCases.getById(id);
      if (result) setItemValues(result);
    } catch (error) {
      showError(error, `Erro ao carregar item de resposta`);
    } finally {
      setLoading(false);
    }
  };

  const handleValidation = () => {
    itemAnswerValidation
      .validate(itemValues, { abortEarly: false })
      .then(async () => {
        if (errors) setErrors({});
        try {
          openBackdrop(true, "Editando resposta");
          await itemAnswerUseCases.update(itemValues);
          await fetchItemAnswer();
          showSuccess(`Item de resposta atualizada com sucesso!`);
        } catch (error) {
          showError(error, `Erro ao editar item de resposta`);
        } finally {
          openBackdrop(false);
        }
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  const handleOpenModal = (type) => {
    switch (type) {
      case "type":
        return openDialogBox({
          customComponent: () => (
            <CreateEditType data={null} onCloseForm={closeDialog} refreshData={getTypesAnswer} />
          ),
        });
      case "valueVariable":
        return openDialogBox({
          maxWidth: "md",
          customComponent: () => (
            <CreateEditValue data={null} onCloseForm={closeDialog} refreshData={() => {}} />
          ),
        });
    }
  };

  useEffect(() => {
    if (typesAnswer.length === 0) getTypesAnswer();
  }, []);

  const LoadingSkeleton = ({ height = "61px" }) => (
    <Skeleton width="100%" variant="rounded" sx={{ borderRadius: "12px" }} height={height} />
  );

  return (
    <Accordion
      elevation={0}
      sx={styles.tab}
      onChange={(_, expended) => {
        if (expended) {
          fetchItem(item.id);
        } else {
          setErrors({});
        }
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={item.id}
        id={item.id}
        disabled={noItemsToShow}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={styles.title}>{itemValues?.title || item.title}</Typography>
          {!noItemsToShow && (
            <Box sx={styles.deleteIcon}>
              <TableActions
                deleteSecurePath={[adminPerms.deleteAnswerItems]}
                handleDelete={(e) => {
                  e.stopPropagation();
                  handleDelete(item.id, "gtAnswerItems");
                }}
              />
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={styles.detailsContainer}>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, lg: 6 }}>
            {!loading ? (
              <Input
                type="text"
                name="title"
                title="Título"
                value={itemValues?.title}
                onChange={(e) => handleChange("title", e.target.value)}
                error={errors.title}
                disabled={!editing}
              />
            ) : (
              <LoadingSkeleton />
            )}
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            {!loading ? (
              <Input
                type="select"
                name="gtAnswerItemType"
                title="Tipo de Resposta"
                value={typesAnswer.find((item) => item.id === itemValues?.gtAnswerItemType?.id)}
                onChange={(e) => handleChange("gtAnswerItemType", { id: e.id, title: e.title })}
                error={errors.gtAnswerItemType}
                options={typesAnswer}
                getOptionLabel={(option) => option.title}
                icon={{
                  title: "Criar tipo de resposta",
                  func: () => handleOpenModal("type"),
                  securePaths: [adminPerms.addAnswerTypes],
                }}
                disabled={!editing}
              />
            ) : (
              <LoadingSkeleton />
            )}
          </Grid>
          <Grid size={12}>
            {!loading ? (
              <>
                <Typography sx={{ ...formStyles.subtitle, color: errors.text && "#d32f2f" }}>
                  Texto
                </Typography>
                <TextEditor
                  initialValue={itemValues?.text}
                  name="text"
                  height={400}
                  handleEditorChange={(content) => {
                    handleChange("text", content);
                  }}
                  error={errors.text}
                  disabled={!editing}
                />
                {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
              </>
            ) : (
              <LoadingSkeleton height="400px" />
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
      <SecurityPath securePaths={[adminPerms.seeAnswerItems]}>
        <AccordionActions disableSpacing sx={styles.actions}>
          {editing && (
            <SecurityPath securePaths={[adminPerms.addValueVariables]}>
              <ButtonCommon
                color="secondary"
                sx={{ width: "fit-content" }}
                onClick={() => handleOpenModal("valueVariable")}
              >
                Criar Variável de Valor
              </ButtonCommon>
            </SecurityPath>
          )}
          <Box sx={{ display: "flex", gap: 1, alignItems: "center", ml: "auto" }}>
            <ButtonCommon
              sx={{ width: 150 }}
              onClick={() => setEditing(!editing)}
              color="secondary"
              variant={editing ? "outlined" : "contained"}
            >
              {editing ? "Cancelar" : "Editar"}
            </ButtonCommon>
            {editing && (
              <ButtonCommon sx={{ width: 150 }} onClick={handleValidation}>
                Salvar
              </ButtonCommon>
            )}
          </Box>
        </AccordionActions>
      </SecurityPath>
    </Accordion>
  );
}

export default ItemAcordion;

const styles = {
  tab: {
    borderRadius: "12px",
    overflow: "hidden",
    backgroundColor: "#D9D9D9",
    "&:hover": {
      backgroundColor: "#C9C9C9",
    },
  },
  title: { fontFamily: "Montserrat", fontSize: 16 },
  deleteIcon: { width: "min-content", height: "12px" },
  detailsContainer: {
    p: 2,
    backgroundColor: "#f0f0f0",
  },
  actions: { justifyContent: "space-between", gap: 1, px: 3 },
};
