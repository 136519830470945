import { Description, PictureAsPdf, SaveAlt, TableChart } from "@mui/icons-material";
import { Button, Popover } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { exportCSV, exportPDF, exportXLSX } from "~/presentation/utils/exportDataTableToFiles";

function ExportFilter({ data, columns, searchType }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const statsFilename = {
    search: `pesquisas_${new Date().toISOString().split("T")[0]}`,
    "search-summary": `resumo-pesquisas_${new Date().toISOString().split("T")[0]}`,
    "content-access": `conteudos-acessados_${new Date().toISOString().split("T")[0]}`,
  };

  const exportOptions = [
    {
      label: "Excel (.csv)",
      icon: Description,
      onClick: () => exportCSV(data, columns, statsFilename[searchType]),
    },
    {
      label: "Excel (.xlsx)",
      icon: TableChart,
      onClick: () => exportXLSX(data, columns, statsFilename[searchType]),
    },
    {
      label: "Documento PDF",
      icon: PictureAsPdf,
      onClick: () => exportPDF(data, columns, statsFilename[searchType]),
    },
  ];
  return (
    <div>
      <Button
        sx={{ color: "#001A50", fontSize: 13, fontWeight: 500 }}
        startIcon={<SaveAlt />}
        aria-describedby={id}
        onClick={handleOpen}
      >
        EXPORTAR
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", py: 1 }}>
          {exportOptions.map(({ label, icon: Icon, onClick }, index) => (
            <Button
              sx={{
                px: 2,
                color: "rgba(0, 0, 0, 0.87)",
                fontWeight: 400,
                textTransform: "none",
                fontSize: "1rem",
                gap: 1,
              }}
              key={`${label}-${index}`}
              onClick={onClick}
            >
              <Icon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              {label}
            </Button>
          ))}
        </Box>
      </Popover>
    </div>
  );
}

export default ExportFilter;
