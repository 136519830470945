import React, { useState, useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { Box, Typography as Text } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Close } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

export const SendAutomated4010 = ({
  nfseDate,
  DigitalCertificate,
  UserCompany,
  CompanyUserId,
  eventSend,
  competence,
  selectedYear,
  selectedMonth,
  closeModal,
  filter,
  openDetailsModal,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const apiSendReinf = new ApiReinfUseCases(makeHttpClient());
  const [eventData, setEventData] = useState({
    companyId: CompanyUserId,
    digitalCertId: DigitalCertificate?.id,
    isProduction: true,
    reinfEvent: 9,
    calculationDate: competence,
    items: nfseDate.map((event) => ({
      receiptNumber: event.receiptNumber,
      docs: event.paymentsData.map((payment) => ({
        id: payment.id,
        serviceProviderCnpj: payment.businessPartnerCNPJ,
      })),
    })),
  });

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : "";
    setEventData((prev) => ({ ...prev, [name]: value }));
  };

  const isProductionoptions = [
    { value: false, label: "Produção Restrita" },
    { value: true, label: "Produção" },
  ];

  const handleSubmit = async () => {
    openBackdrop(true, `Enviando dados do Evento R-${eventSend}...`);
    try {
      filter.event = `r${eventSend}`;
      const response = await apiSendReinf.sendRetification(eventData);
      if (response) openDetailsModal(response);
    } catch (error) {
      showError(error, "Erro");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid size={{ xs: 6, sm: 11 }}>
          <Text style={styles.Title}>DADOS DO EVENTO {`R-${eventSend}`}</Text>
        </Grid>
        <Grid
          size={{ xs: 6, sm: 1 }}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Close onClick={closeModal} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Estabelecimento"
            name="companyName"
            value={UserCompany?.name || ""}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Certificado Digital"
            name="digitalCertId"
            value={DigitalCertificate?.name}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="select"
            title="Tipo de ambiente"
            name="isProduction"
            options={isProductionoptions}
            onChange={(option) => handleSelectChange(option, { name: "isProduction" })}
            value={isProductionoptions.find((option) => option.value === eventData.isProduction)}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Data da competência"
            value={`${
              selectedMonth.toString().length === 1 ? `0${selectedMonth}` : selectedMonth
            }/${selectedYear}`}
          />
        </Grid>
        <Box sx={{ paddingInline: 3, paddingBlock: 3 }}>
          <Text style={styles.subTitle}>
            Foram selecionados {nfseDate?.length} Eventos para envio
          </Text>
        </Box>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={closeModal}>
          Cancelar
        </RButton>
        <RButton sx={{ width: "150px" }} variant="contained" color="primary" onClick={handleSubmit}>
          Enviar {`R-${eventSend}`}
        </RButton>
      </Box>
    </>
  );
};
const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
  subTitle: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
};
