import {
  ContentCopy,
  DoneAll,
  LockReset,
  Refresh,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

function GeneratePass({ handleGeneratePassword }) {
  const { showInfo, showError } = useContext(SnackbarContext);
  const [newPass, setNewPass] = useState("");
  const [copyed, setCopyed] = useState(false);
  const [see, setSee] = useState(false);
  const [loading, setLoading] = useState(false);

  const [passAnchor, setPassAnchor] = useState(false);
  const handleOpenPassAnchor = () => setPassAnchor(true);
  const handleClosePassAnchor = () => setPassAnchor(false);

  const generetaNewPass = async () => {
    if (!newPass) {
      try {
        setLoading(true);
        const result = await handleGeneratePassword();
        if (result) setNewPass(result);
        handleOpenPassAnchor();
      } catch (error) {
        showError(error, "Erro ao gerar senha");
      } finally {
        setLoading(false);
      }
    } else {
      handleClosePassAnchor();
      setNewPass("");
    }
  };

  const copyNewPass = () => {
    if (!copyed) {
      try {
        navigator.clipboard.writeText(newPass);
        showInfo("Senha copiada para a área de transferência...");
        setCopyed(true);
      } catch (error) {
        showError(error, "Erro ao copiar senha");
      }
    }

    setTimeout(() => {
      setCopyed(false);
    }, [2000]);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Tooltip title="Gerar uma nova senha">
        <IconButton
          onClick={generetaNewPass}
          sx={{ ...formStyles.buttonIcon, position: "relative" }}
        >
          {loading ? (
            <Refresh sx={{ ...formStyles.buttonIcon.icon, ...styles.loop }} />
          ) : (
            <LockReset sx={formStyles.buttonIcon.icon} />
          )}
        </IconButton>
      </Tooltip>
      {passAnchor && (
        <Box sx={styles.popover}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              px: 1,
              fontSize: "14px",
            }}
          >
            {see ? newPass : "******************"}
          </Typography>
          <IconButton onClick={() => setSee(!see)}>
            {see ? <VisibilityOff sx={styles.icon} /> : <Visibility sx={styles.icon} />}
          </IconButton>
          <IconButton onClick={copyNewPass}>
            {copyed ? <DoneAll sx={styles.icon} /> : <ContentCopy sx={styles.icon} />}
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default GeneratePass;

const styles = {
  popover: {
    position: "absolute",
    right: "25px",
    top: "45px",
    display: "flex",
    backgroundColor: "white",
    alignItems: "center",
    gap: 0,
    p: 1,
    borderRadius: "12px",
  },
  icon: { fontSize: 16, color: "#001A50" },
  loop: {
    animation: "spin 1s ease-in-out infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  },
};
